/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react';
import { css } from './css';
import { appColors } from '../../emotion/utils';
import React, { useState, useEffect } from 'react';
import ModalButtonSet from '../modal-set-button/modal-set-button';
import FormTextInput from '../../components/form-text-input/form-text-input';
import FormTextarea from '../../components/form-textarea/form-textarea';
import FormSelect from '../../components/form-select/form-select-simplified';
import { useMutation } from '@tanstack/react-query';
import Client from '../../client';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import addHorseSchema from '../../schemas/addHorseSchema';
import { useQuery } from '../../hooks';
import FormFileUpload from '../../components/form-file-upload';

export default function AddHorseModal({
  setShowModal = () => {},
  open = true,
  onSuccess = () => {},
}) {
  const initialValues = {
    name: '',
    description: '',
    ferrier_name: '',
    ferrier_phone: '',
  }
  const handleClose = () => {
    setShowModal(false)
  }

  const [hasError, setHasError] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(open)

  const handleCancel = () => {
    setIsModalOpen(false)
    setShowModal(false)
  }

  const { mutate: submitRequest, isLoading } = useMutation({
    mutationKey: 'add-horse',
    networkMode: 'always',
    mutationFn: async data => {
      const response = Client.post('/horses', data);
      return response;
    },
    onSuccess: values => {
      console.log('on submit test..');
      setShowModal(false)
      onSuccess(values.body)
    },
    onError: () => {
      setHasError(true)
    },
  });

  const { data: stallData } = useQuery("/stalls");

  const stallOptions = stallData?.data?.map(stall => ({
    value: stall.id,
    label: stall.name,
  }));

  return (
    <Dialog
      header={
        <span css={css.dialogHeader} className="dialog-header">
          Add New Horse
        </span>
      }
      visible={isModalOpen}
      style={{ width: '400px' }}
      onHide={handleCancel}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={submitRequest}
        validationSchema={addHorseSchema}
      >
        {formik => (
          <Form>
            <FormTextInput
              formik={formik}
              name="name"
              label="Horse Name"
              labelPosition="top"
              placeholder="Name"
              required
            />
            {formik.touched.name && formik.errors.name && (
              <div css={css.feedback}>{formik.touched.name && formik.errors.name}</div>
            )}

            <FormSelect
              formik={formik}
              name="assign_stall"
              label="Assign Stall"
              labelPosition="top"
              required
              placeholder="Select"
              options={stallOptions}
            />
            {formik.ertouchedssign && formik.errors.assign_stall && (
              <div css={css.feedback}>{formik.ertouchedssign && formik.errors.assign_stall}</div>
            )}

            <FormTextInput
              formik={formik}
              name="ferrier_name"
              label="Ferrier Name"
              labelPosition="top"
              placeholder="First Last"
              required
            />
            {formik.errtouchedrrier && formik.errors.ferrier_name && (
              <div css={css.feedback}>{formik.errtouchedrrier && formik.errors.ferrier_name}</div>
            )}

            <FormTextInput
              formik={formik}
              name="ferrier_phone"
              label="Ferrier Phone"
              labelPosition="top"
              placeholder="(123) 456-7890"
              required
            />
            {formik.errtouchedrrier && formik.errors.ferrier_phone && (
              <div css={css.feedback}>{formik.errtouchedrrier && formik.errors.ferrier_phone}</div>
            )}

            <FormTextarea
              formik={formik}
              name="description"
              label="Notes"
              placeholder="Placeholder"
              labelPosition="top"
              required
            />
            {formik.errors.touchedption && formik.errors.description && (
              <div css={css.feedback}>{formik.errors.touchedption && formik.errors.description}</div>
            )}

            <FormFileUpload
              name="image"
              label="Horse Image"
              formik={formik}
              placeholder="Click to add horse image"
              helperText="Accepts png and jpeg"
            />

            <ModalButtonSet
              onCancel={handleCancel}
              onSubmit={formik.handleSubmit}
              isSubmitting={formik.isSubmitting}
              submitButtonText="Create"
              submitButtonColor={appColors.greens.base}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
