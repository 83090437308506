/** @jsxImportSource @emotion/react */

import { useParams } from 'react-router-dom';
import VideoPlayer from '../../components/video-player/video-player';
import VideoComponent from '../../components/video-component/video-component';
import NoteListingComponent from '../../components/note-listing-component/note-listing-component';
import NoteEntryComponent from '../../components/note-entry-component/note-entry-component';
import RequestVetButton from '../../components/request-vet-button/request-vet-button';
import DeleteClipButton from '../../components/delete-clip-button/delete-clip-button';
import TakeClipButton from './components/take-clip-button/take-clip-button';
import { useQuery } from '../../hooks';
import ClipCard from './components/clip-card/clip-card';
import React, { useMemo, useState, useEffect } from 'react';
import { css } from './css';
import { ENDPOINT } from '../../client';
import { selectedCurrentCamera } from "../../store/cameraSlice";
import { selectedClipFile } from "../../store/viewerSlice";
import { useAppDispatch, useAppSelector } from '../../shared/model';

export default function ViewHorse() {
  const { id: horseId } = useParams();

  const selectedClip = useAppSelector(selectedClipFile);

  const [firstCamera, setFirstCamera] = useState({});

  const {
    data: showClass,
    refetch,
    isLoading,
    isError,
  } = useQuery(`/horse/${horseId}`);

  const camerasUri = useMemo(() => {
    const url = new URL("/cameras", ENDPOINT);
    url.searchParams.append(
      "horse_id",
      horseId
    );
    return url.toString().replace(ENDPOINT, "");
  }, []);

  const { data: notesData, refetch: refetchNotes } = useQuery(
    `/notes?horse_id=${horseId}`
  );

  // Get clips
  const { data: clipsData } = useQuery(
    `/clips?horse_id=${horseId}`
  );

  // Get cameras
  const { data: camerasData } = useQuery(
    camerasUri
  );

  // get first camera
  useEffect(() => {
    if (camerasData && camerasData[0]) {
      const firstCamera = camerasData[0];
      setFirstCamera(firstCamera);
    }
  }, [camerasData]);

  const notesRows = useMemo(() => {
    let notes = [];

    notesData?.data?.forEach((row) => {
      notes.push({
        ...row,
        name: row.name,
        id: row.id,
      });
    });

    return notes;
  }, [notesData?.data]);

  const clipsRows = useMemo(() => {
    let clips = [];

    clipsData?.data?.forEach((row) => {
      clips.push({
        ...row
      });
    });

    return clips;
  }, [clipsData?.data]);

  const defaultNotes = [];

  const defaultClips = [];

  const clipsToDisplay = clipsData?.data?.length > 0 ? clipsData.data : defaultClips;

  const notesToDisplay =
    notesRows && notesRows.length > 0 ? notesRows : defaultNotes;

  const onFetchMoreNotes = () => {
    console.log('on fetch more notes fired.');
    refetchNotes();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading data</div>;
  }

  return (
    <>
      <div className="container">
        <div css={css.videocard}>
          <div className="videoplayer" css={css.videoplayer}>
            <VideoComponent></VideoComponent>
          </div>
          <div className="notes" css={css.notes}>
            <h2>Previous Notes</h2>
            <div className="notes-area">
              {notesToDisplay.length > 0 && (
                <NoteListingComponent
                  css={css.stallNotes}
                  notes={notesToDisplay}
                />
              )}
            </div>
            <div className="actions-area" css={css.actionsarea}>
              <div className="stallNoteEntry" css={css.stallNoteEntry}>
                <NoteEntryComponent className="stallNoteEntry" onFetchMore={onFetchMoreNotes} />
              </div>
              <div className="requestVetButton">
                <RequestVetButton />
              </div>
              <div className="deleteClipButton">
                <DeleteClipButton />
              </div>
              <div className="takeClipButton">
                <TakeClipButton />
              </div>
            </div>
          </div>
        </div>
        <div css={css.savedclips}>
          <div>
            <h2>Saved Clips</h2>
          </div>
          <div css={css.clips}>
            {clipsToDisplay.map((clip) => (
              <ClipCard key={clip.id} data={clip} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}