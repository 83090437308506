/** @jsxImportSource @emotion/react */
import { css } from "./css";
import React, { useEffect, useState } from "react";
import NoteListingComponent from "../../components/note-listing-component/note-listing-component";
import VideoComponent from "../../components/video-component/video-component";
import CameraSelectorComponent from "../../components/camera-selector-component/camera-selector-component";
import NoteEntryComponent from "../../components/note-entry-component/note-entry-component";
import RequestVetButton from "../../components/request-vet-button/request-vet-button";
import { Dropdown } from 'primereact/dropdown';
import { useQuery } from "../../hooks";
import { ENDPOINT } from "../../client";
import Button from "../../components/button/button";

export default function StallTray(props) {
  const {
    activeStall,
    setActiveStall,
    refetchStall,
  } = props;

  const [activeCamera, setActiveCamera] = useState(null);
  const [activeHorse, setActiveHorse] = useState(null);
  const [horseList, setHorseList] = useState(null);
  const [notesUri, setNotesUri] = useState("/notes");

  useEffect(() => {
    console.log('check active horse..', activeHorse);
    if (activeHorse) {
      const horseId = activeHorse.id;
      const url = new URL("/notes", ENDPOINT);
      url.searchParams.append(
        "horse_id",
        horseId
      );
      console.log("url is..", url);
      const compiledUrl = url.toString().replace(ENDPOINT, "");
      console.log('compiled url..', compiledUrl);
      setNotesUri(compiledUrl);
    }
  }, [activeHorse]);
  
  const { data: notesData, refetch } = useQuery(notesUri);

  useEffect(() => {
    if(activeStall?.camera?.length > 0) 
      setActiveCamera(activeStall?.camera[0])
    const opts = activeStall?.horse?.map((horse) => {
      return {
        label: horse.name,
        value: horse.id
      }
    })
    setHorseList(opts)
    if(opts)setActiveHorse(activeStall?.horse[0])

  }, [activeStall])

  const onFetchMoreNotes = () => {
    console.log('on fetch more notes fired.');
    //getNotes(activeHorse?.id);
    refetch();
  };

  const getNotes = (horseId) => {
    let filteredNotes = [];

    notesData?.data?.map((row) => {
       if (row?.horseId === horseId) {
         filteredNotes.push({
           ...row,
           name: row.name,
           id: row.id,
         });
       }    
    });

    return <NoteListingComponent css={css.stallNotes} notes={filteredNotes} />;
  };

  const videoBlock = (
    <div css={css.videoBlock} className="videoContentArea">
      {setActiveStall && <Button onClick={() => setActiveStall(null)}>Close Me</Button>}
      {/* {currentCamera && <VideoComponent currentCamera={currentCamera} />} */}
      <VideoComponent currentCamera={activeCamera} />
      
      <CameraSelectorComponent activeCamera={activeCamera} setActiveCamera={setActiveCamera} activeStall={activeStall} refetch={refetchStall}/>
      <Dropdown
        onChange={(it) => {setActiveHorse(activeStall?.horse?.find((i) => i.id === it?.value))}}
        options={horseList}
        placeholder="Select a Horse"
        value={activeHorse?.id}
        />
    </div>
  );

  const headerBlock = (
    <div css={css.stallCardHeader} className="horseDescriptorsArea">
      <h1>{activeHorse?.name}</h1>
    </div>
  );

  const notesblock = (
    <div css={css.stallNoteSection} className="horseNotesArea">
      <h3>Notes</h3>
      {getNotes(activeHorse?.id)}
    </div>
  );

  const addNoteForm = activeHorse && (
    <div className="stallNoteEntry" css={css.stallNoteEntry}>
      <NoteEntryComponent
        className="stallNoteEntry"
        horse={activeHorse}
        onFetchMore={onFetchMoreNotes}
      />
    </div>
  )

  const btnRequestVet = <RequestVetButton />;

  const actions = [btnRequestVet];

  const actionsBlock = (
    <div css={css.tray.actions} className="requestVetButton">
      {actions.map((btn, index) => (
        <React.Fragment key={index}>{btn}</React.Fragment>
      ))}
    </div>
  );

  return (
    <div css={css.tray.container}>
      {videoBlock}
      {headerBlock}
      {notesblock}
      {addNoteForm}
      {actionsBlock}
    </div>
  );
}