import React, { useState } from "react";
import Header from "../header/header";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { appColors } from "../../emotion/utils";

const Main = () => {
  const loc = useLocation();
  const [activePage, setActivePage] = useState(loc.pathname.slice(1));
  console.log('active page..', activePage);

  return (
    <div className="site">
      <Header activePage={activePage} setActivePage={setActivePage} />
      <main style={{ backgroundColor: appColors?.grays?.[0]}}>
        <Outlet context={[activePage, setActivePage]} />
      </main>
    </div>
  );
};

export default Main;