/** @jsxImportSource @emotion/react */
import { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { useQuery } from "../../hooks";
import StallSection from "./stall-section";
import StallTray from "./stall-tray";
import { css } from './css'

function Stalls() {

  const toastRef = useRef();
  const [activeStall, setActiveStall] = useState(null);

  const { data, refetch } = useQuery(
    `/stalls`,
  );


  return (
    <>
       <Toast ref={toastRef} position="top-center" />
      <div className="container">
            <StallSection 
              data={data?.data} 
              setActiveStall={setActiveStall}
              activeStall={activeStall}
              className={activeStall ? "tray-open" : ""}
            />
          
        <div css={css.tray} className={activeStall ? "open" : "closed"}>
            {activeStall && 
              <StallTray activeStall={activeStall} setActiveStall={setActiveStall} refetchStall={refetch} />  
            }
        </div>
      </div>
      
    </>
  );
}

export default Stalls;