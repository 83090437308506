/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import StallCard from './stall-card';
import { DndContext, KeyboardSensor, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import {SortableContext, arrayMove} from '@dnd-kit/sortable';
import {useDroppable} from '@dnd-kit/core';
import Client from '../../client';
import _ from "lodash";

import { css } from './css';

const StallSection = (props) => {
  const {
    data = [],
    activeStall,
    setActiveStall=()=>{},
    className = ''
  } = props;

  const {setNodeRef} = useDroppable({
    id: 'unique-id',
  });
  const [items, setItems] = useState([])

  useEffect(() => {
    if(data && data.length > 0)
      setItems(data?.map((s) => s.id));
  }, [data])

  const sortOrderDifferent = ((newItems) => {
    const oldItems = data?.map((d) => {
      return d.id
    })

    return !_.isEqual(oldItems, newItems)
  })
  useEffect(() => {
    if(items && items.length > 0) {
      const data = items.map((item, idx) => {
        return {
            id:item,
            sort_order:idx
          }
        }
      )
      const putIt = async (data) => {
        await Client.put("/stalls/stall-order", {stalls: data})
      }
      if(data && sortOrderDifferent)
        putIt(data)
    }
  }, [items])
  const stallData = data;
  
  function handleDragEnd(event) {

    if(event.active.id !== event.over.id) {
      const oldIdx = items.indexOf(event.active.id)
      const newIdx = items.indexOf(event.over.id)
      const newItems = arrayMove(items, oldIdx, newIdx)
      setItems(newItems)
    }
  }

  const stallsBlock = items?.map(item => {
    const stall = stallData.find((stall) => {
      return stall.id === item
    })
    
    return (
    <StallCard
      key={stall.id}
      data={stall}
      id={stall.id}
      setActiveStall={setActiveStall}
      isActive={activeStall?.id === stall?.id}
    />
    
  )})

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10, // Enable sort function when dragging 10px
    },
  })
  const keyboardSensor = useSensor(KeyboardSensor)
  const sensors = useSensors(mouseSensor, keyboardSensor)

    
  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
    <div
      css={css.cardSection}
      className={className}
      id="droppable"
      ref={setNodeRef}
    >
      <SortableContext items={items}>
        {stallsBlock}
      </SortableContext>
    </div>
    </DndContext>
  )
}
export default StallSection;