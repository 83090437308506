/** @jsxImportSource @emotion/react */

import { css } from './css';
import { appColors } from '../../emotion/utils';
import React, { useState, useMemo, useEffect } from 'react';
import ModalButtonSet from '../modal-set-button/modal-set-button';
import { Dialog } from 'primereact/dialog';
import { useMutation } from '@tanstack/react-query';
import Client from '../../client';
import { Form, Formik } from 'formik';
import FormTextInput from '../../components/form-text-input/form-text-input';
import FormSelect from '../../components/form-select/form-select-simplified';
import FormRadioButton from '../form-radio-button/form-radio-button';
import { useQuery } from '../../hooks';
import { ENDPOINT } from '../../client';

import addUserSchema from '../../schemas/addUserSchema';

export default function AddUserModal({
  setShowModal = () => {},
  open,
  onSuccess = () => {},
}) {
  const [horses, setHorses] = useState([]);

  const rolesUri = useMemo(() => {
    const url = new URL("/roles", ENDPOINT);
    return url.toString().replace(ENDPOINT, "");
  }, []);

  const horsesUri = useMemo(() => {
    const url = new URL("/horses?orderBy=name", ENDPOINT);
    return url.toString().replace(ENDPOINT, "");
  }, []);

  // get roles
  const { data: rolesData, refetch: refetchRoles } = useQuery(
    rolesUri
  );

  // get horses
  const { data: horsesData, refetch: refetchHorses } = useQuery(
    horsesUri
  );

  useEffect(() => {
    if (horsesData && horsesData.data) {
      let horses = horsesData.data;
      let horsesList = [];
      for (let counter = 0; counter < horses.length; counter++) {
        let horseItem = horses[counter];
        let label = horseItem.name;
        let value = horseItem.id;
        horsesList.push({
          value,
          label
        });
      }
      setHorses(horsesList);
    }
  }, [horsesData]);

  const [isModalOpen, setIsModalOpen] = useState(open);

  const { mutate: submitRequest, isLoading } = useMutation({
    mutationKey: 'add-user',
    networkMode: 'always',
    mutationFn: async data => Client.post('/users', data),
    onSuccess: (values) => {
      setShowModal(false);
      onSuccess(values.body);
    }
  });

  const initialValues = {
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    role: '',
    assign_horse: [],
  };

  if (isLoading) {
    return <div>Loading...</div>
  }

  const handleCancel = () => {
    setShowModal(false)
  };

  const roles = rolesData && rolesData.data ? rolesData.data : [];

  return (
    <>
      <Dialog
        header={
          <span css={css.dialogHeader} className="dialog-header">
            Add New User
          </span>
        }
        visible={isModalOpen}
        style={{ width: '400px' }}
        onHide={handleCancel}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            await submitRequest(values)
            setSubmitting(false)
          }}
          validationSchema={addUserSchema}
          validateOnBlur
          validateOnChange
        >
          {formik => (
            <Form>
              <div css={css.container}>
                <div className="roleSelector">
                  {roles.map((role) => {
                    return (
                      <FormRadioButton
                        key={role.name}
                        name="role"
                        value={role.name}
                        label={role.name}
                        onBlur={formik.handleBlur}
                      />
                    );
                  })}
                </div>
              </div>
              {formik.errors.role && (
                <div css={css.feedback}>{formik.touched.role && formik.errors.role}</div>
              )}
              <FormTextInput
                formik={formik}
                name="email"
                label="Email"
                labelPosition="top"
                placeholder="email@domain.com"
                required
                onBlur={formik.handleBlur}
                onFocus={() => formik.setFieldError('email', '')}
              />
              {formik.errors.email && (
                <div css={css.feedback}>{formik.touched.email && formik.errors.email}</div>
              )}
              <FormTextInput
                formik={formik}
                name="first_name"
                label="First Name"
                labelPosition="top"
                placeholder="First Name"
                required
                onBlur={formik.handleBlur}
                onFocus={() => formik.setFieldError('first_name', '')}
              />
              {formik.errors.first_name && (
                <div css={css.feedback}>{formik.touched.first_name && formik.errors.first_name}</div>
              )}
              <FormTextInput
                formik={formik}
                name="last_name"
                label="Last Name"
                labelPosition="top"
                placeholder="Last Name"
                required
                onBlur={formik.handleBlur}
                onFocus={() => formik.setFieldError('last_name', '')}
              />
              {formik.errors.last_name && (
                <div css={css.feedback}>{formik.touched.last_name && formik.errors.last_name}</div>
              )}
              
              <FormTextInput
                formik={formik}
                name="phone"
                label="Phone"
                labelPosition="top"
                placeholder="(123) 456-7890"
                onBlur={formik.handleBlur}
              />
              <FormSelect
                formik={formik}
                name="assign_horse"
                label="Assign Horse(s)"
                placeholder="Select All That Apply"
                options={horses}
                isMulti={true}
                onBlur={formik.handleBlur}
              />
              <ModalButtonSet
                disabled={isLoading || !formik.isValid || !formik.dirty}
                onCancel={handleCancel}
                isSubmitting={formik.isSubmitting}
                submitButtonText="Create"
                submitButtonColor={appColors.greens.base}
              />
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}
