/** @jsxImportSource @emotion/react */

import Icon from "../../components/icon/icon";
import {useDraggable} from '@dnd-kit/core';
import {useSortable} from '@dnd-kit/sortable';
import { forwardRef } from "react";
import {CSS} from '@dnd-kit/utilities';
import moment from "moment";

import { css } from "./css";

const StallCard = (props) => {
  const { data, onClick, setActiveStall, isActive, id } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const horses = data.horse.map(h => h.name).slice(0, 2).join(' | ');

  const headerBlock = (
    <div css={css.cardHeader}>
      <h1>{data?.name}</h1>
      <span>{horses}</span>
    </div>
  );

  const horseImg = data?.image;

  const imgBlock = horseImg
    ? <img src={horseImg?.link} alt={data?.name || ""} />
    : (
      <div css={css.stallCard().plc}>
        <Icon icon="Horse" />
      </div>
    );

  // const tagsBlock = <Tags />;

  const onClickStall = () => {
    setActiveStall(data)
  };

  return (
    <div id={id} css={css.stallCard(isActive)} className="stallcard"  ref={setNodeRef} style={style} {...listeners} {...attributes}>
      <div onClick={onClickStall}>
        {headerBlock}
        {imgBlock}
        <div style={{fontSize:"0.7em"}}>{data?.image?.created_at ? moment(data?.image?.created_at).format("MM/DD/YYYY HH:MM") : ""}</div>
        {/*{tagsBlock}*/}
      </div>
    </div>
  );
};
export default StallCard;